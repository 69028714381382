import React from 'react';
import { Heading, SubHeading } from '../styles/globalStyles';

function HomePage() {
    return (
        <div>
            <Heading>Homepage Designs</Heading>
            <SubHeading>Design inspiration for your new home</SubHeading>
        </div>
    );
}

export default HomePage;
