import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import HomePage from './HomePage';
import { StyledMenu } from '../styles/globalStyles';

function App() {
    return (
        <div>
            <StyledMenu pointing secondary>
                <Menu.Item name='home' as={NavLink} to='/' exact />
                <Menu.Item name='about' as={NavLink} to='/about' />
                <Menu.Item name='contact' as={NavLink} to='/contact' />
            </StyledMenu>
            <Routes>
                <Route path="/" element={<HomePage />} />
            </Routes>
        </div>
    );
}

export default App;
