import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';

export const StyledMenu = styled(Menu)`
  width: 100%;
  border-radius: 0 !important; // Remove default border-radius
`;

export const Heading = styled.h1`
  font-size: 48px;
  color: black;
  margin-bottom: 20px;
`;

export const SubHeading = styled.h2`
  font-size: 24px;
  color: gray;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const StyledImage = styled.img`
  width: 150px;
  height: auto;
  margin-right: 10px;
`;
